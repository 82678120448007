import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Styles/App.css'
import Main from './Pages/Main';

function App() {
  return (
  <>
    
            <BrowserRouter>
              
                <Routes>
                <Route path="/" element={<Main />} />
                  
                </Routes>
                </BrowserRouter>
  
  </>
  );
}

export default App;
